import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import SubHeader from '../components/subheader'

const NotFound = () => (
<Layout>
    <SEO title="404 not found" />
    <SubHeader title='404 not found'/>
    <div id="blog-single">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12">
                    <div className="blog-post">
                    	<div className="post-content">
	                        <h1>NOT FOUND</h1>
	                        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Layout>
)

export default NotFound
